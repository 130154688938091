angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('public.login', {
            url: '/login',
            views: {
                "public": {
                    controller: 'LoginCtrl',
                    templateUrl: 'web/public/login/login.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Login'
            }
        });
    })

    .controller('LoginCtrl', function ($rootScope, $scope, loginService, session, $state, $translate, fingerinkSession, $q, fingerinkService, $http, $window) {

        var that = this;

        that.loading = false;

        this.enviar = function () {
            that.loading = true;
            loginService.login(this.username, md5(this.password)).then(function (response) {
                that.loading = false;
                if (response.data.token) {
                    /*
                    if (!response.data.user.emailConfirmed) {
                        swal($translate.instant("Confirma tu correo"), $translate.instant("Este correo no está confirmado, por favor, revisa tu bandeja de entrada y confirma tu dirección de correo"), "warning");
                        that.loading = false;
                    } else {
                    */
                    session.put(response.data);
                    $state.go('main.dashboard');
                    if (FingerinkEnvironmentAngular.trackGoogleFacebookTags) {
                        fbq('track', 'LogIn');
                        $window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'Login',
                            eventAction: 'login',
                            eventLabel: ''
                        });
                    }
                    // }
                } else {
                    swal($translate.instant('Algo ha ido mal'), $translate.instant("¡Usuario o contraseña incorrectos!"), "error");
                    that.loading = false;
                }
            }, function (response) {
                if (response.data && response.data.errorsHTTP == "ADMIN_MUST_DELETE_USERS") {
                    swal($translate.instant('¡Tu empresa no tiene suficientes usuarios contratados!'), $translate.instant("Por favor, contacte con el administrador de su empresa para que contrate un plan superior o deshabilite usuarios"), "error");
                }

                if (response.data && response.data.errorsHTTP == "USER_DISABELD") {
                    swal($translate.instant('¡Usuario deshabilidado!'), $translate.instant("Por favor, contacte con el administrador de su empresa"), "error");
                }

                if (response.data && response.data.errorsHTTP == "USER_NOT_FOUND") {
                    swal($translate.instant('¡Login incorrecto!'), $translate.instant("Nombre de usuario o contraseña incorrectos"), "error");
                }

                that.loading = false;

            });
        };

        this.recoverPassword = function () {
            that.loadingSendPasswordMail = true;
            loginService.passwordRecover(that.email).then(function (response) {
                that.loadingSendPasswordMail = false;
                swal($translate.instant("¡Correo enviado!"), $translate.instant("Te hemos mandado los pasos a seguir para cambiar la contraseña en un correo electrónico."), "success");
                that.recover = false;
            }, function (response) {
                that.loadingSendPasswordMail = false;
                swal($translate.instant('Algo ha ido mal'), $translate.instant("No hemos encontrado ningún usuario registrado con este correo electrónico."), "error");
            });
        };

        function checkDominioCorrecto(paises, location, dominio) {
            for (var i = 0; i < paises.length; i++) {
                if (location.country == paises[i].countryCode) {
                    if (paises[i].domain == dominio) {
                        return;
                    } else {
                        that.domainQueDeberiaIr = paises[i];
                    }
                }
            }
        }


        $q.all([fingerinkService.getCountries(), $http({
            url: 'https://ipapi.co/json/'
        })]).then(function (responses) {
            checkDominioCorrecto(responses[0].data, responses[1].data, $rootScope.dominio);
        });


    })

    ;
